<template>
  <div>
    <molecules-custom-dialog
      v-if="showPreApprovalBulk"
      :show="showPreApprovalBulk"
      title="Catatan Status"
      caption="Berikan alasan anda"
      :items="[
        {
          title: 'Batalkan',
          override: 'transparent',
          full: false,
          exec: () => (showPreApprovalBulk = null),
        },
        {
          title: 'Approve Semua',
          override: 'primary--text',
          full: false,
          exec: () => {
            approveBulkRequest(showPreApprovalBulk)
            showPreApprovalBulk = null
          },
        },
      ]"
    >
      <atoms-text-field
        color="background"
        label="Alasan approve untuk semua pekerja pada list"
        :noLabel="true"
        v-model="statusNote"
    /></molecules-custom-dialog>
    <molecules-custom-dialog
      v-if="showPreRejectBulk"
      :show="showPreRejectBulk"
      title="Catatan Status"
      caption="Berikan alasan anda"
      :items="[
        {
          title: 'Batalkan',
          override: 'transparent',
          full: false,
          exec: () => (showPreRejectBulk = null),
        },
        {
          title: 'Reject Semua',
          override: 'primary--text',
          full: false,
          exec: () => {
            approveBulkRequest(showPreRejectBulk, false)
            showPreRejectBulk = null
          },
        },
      ]"
    >
      <atoms-text-field
        color="background"
        label="Alasan reject untuk semua pekerja pada list"
        :noLabel="true"
        v-model="statusNote"
    /></molecules-custom-dialog>
    <molecules-custom-dialog
      v-if="showPreReject"
      :show="showPreReject"
      title="Catatan Status"
      caption="Berikan alasan anda"
      :items="[
        {
          title: 'Batalkan',
          override: 'transparent',
          full: false,
          exec: () => (showPreReject = null),
        },
        {
          title: 'Reject',
          override: 'red--text',
          full: false,
          exec: () => {
            approveRequest(showPreReject, false)
            showPreReject = null
          },
        },
      ]"
    >
      <atoms-text-field color="background" label="Alasan Reject" :noLabel="true" v-model="statusNote"
    /></molecules-custom-dialog>
    <molecules-custom-dialog
      v-if="showPreApproval"
      :show="showPreApproval"
      title="Catatan Status"
      caption="Berikan alasan anda"
      :items="[
        {
          title: 'Batalkan',
          override: 'transparent',
          full: false,
          exec: () => (showPreApproval = null),
        },
        {
          title: 'Approve',
          override: 'primary--text',
          full: false,
          exec: () => {
            approveRequest(showPreApproval)
            showPreApproval = null
          },
        },
      ]"
    >
      <atoms-text-field color="background" label="Alasan Approve" :noLabel="true" v-model="statusNote"
    /></molecules-custom-dialog>
    <v-form @submit.prevent="loadData" id="filter" class="">
      <v-row justify="space-between">
        <v-col cols="12" lg="8">
          <atoms-text-field
            label="Cari Pekerja"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
            class="accent rounded-lg"
          />
        </v-col>

        <v-col cols="12" lg="2" class="d-flex justify-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary"
            ><v-icon left>mdi-magnify</v-icon>Cari Pekerja</atoms-button
          >
        </v-col>

        <v-col cols="12" lg="2">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getRequest()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tgl : {{ sort }}
          </atoms-button>
        </v-col>
        <v-col cols="12" class="mb-5">
          <v-form
            v-if="!$store.state.client.data || !client.enabled"
            @submit.prevent="
              () => {
                getClients()
                client.term = ''
              }
            "
          >
            <atoms-text-field
              label="Cari Client"
              :no-label="true"
              v-model="client.term"
              appendIcon="mdi-magnify"
              :appendAction="
                () => {
                  getClients()
                  client.term = ''
                }
              "
            />
          </v-form>
          <atoms-text-field v-else label="Cari Client" :no-label="true">
            <v-row dense>
              <v-col cols="1">
                <atoms-button
                  icon
                  @click="
                    () => {
                      client = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      loadData()
                    }
                  "
                  ><v-icon>mdi-delete</v-icon></atoms-button
                >
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="client.value"
                  :items="$store.state.client.data.map((x) => x.name)"
                  placeholder="Pilih Client"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <!-- <v-col cols="12" lg="auto">
          <v-combobox
                class="rounded-lg"
                placeholder="Filters"
                prepend-inner-icon="mdi-filter-variant"
                :items="filters.map((x) => x.title)"
                v-model="filter"
                @change="loadData"
                hide-details
                multiple
                dense
                solo
                flat
                required
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
            />
        </v-col> -->
      </v-row>
    </v-form>
    <v-row align="center" dense>
      <v-col class="shrink" v-if="checkboxItems && checkboxItems.length > 0 && requestData && requestData.length > 0">
        <v-checkbox :disabled="mainLoading" v-model="checkAll" />
      </v-col>
      <v-col v-if="checkboxItems && checkboxItems.length > 0" cols="6" lg="2">
        <atoms-button
          :disabled="mainLoading"
          class="teal white--text"
          style="width: 100%"
          @click="showPreApprovalBulk = checkboxItems"
        >
          Approve {{ checkboxItems.length || 0 }} Request
        </atoms-button>
      </v-col>
      <v-col v-if="checkboxItems && checkboxItems.length > 0" cols="6" lg="2">
        <atoms-button
          :disabled="mainLoading"
          class="red white--text"
          style="width: 100%"
          @click="showPreRejectBulk = checkboxItems"
        >
          Reject {{ checkboxItems.length || 0 }} Request
        </atoms-button>
      </v-col>

      <v-col cols="12" lg="2">
        <v-combobox
          class="rounded-lg"
          placeholder="Filters"
          prepend-inner-icon="mdi-filter-variant"
          :items="filters.map((x) => x.title)"
          v-model="filter"
          @change="loadData"
          hide-details
          multiple
          dense
          solo
          flat
          required
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </v-col>

      <v-col cols="12" lg="auto" class="d-flex justify-center justify-md-end align-center py-0 my-0 ml-5">
        <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text class="mr-5"
          >Filter dengan tanggal ?</atoms-text
        >
        <v-checkbox v-model="enableCaptainConfirm" hide-spin-buttons /><atoms-text>Kapten Confirm</atoms-text>
      </v-col>
      <v-scroll-y-transition>
        <v-col cols="12" v-if="enableDate" class="my-0">
          <v-row dense>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Dari Tanggal"
                :modal="from.modal"
                :value="from.value"
                @change="
                  (modal, value) => {
                    from.modal = modal
                    from.value = value
                  }
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Sampai Tanggal"
                :modal="to.modal"
                :value="to.value"
                @change="
                  (modal, value) => {
                    to.modal = modal
                    to.value = value
                  }
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-scroll-y-transition>
    </v-row>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div
          v-if="
            (requestData && requestData.result && requestData.result.length > 0) ||
            (enableCaptainConfirm && captainConfirm && captainConfirm.result && captainConfirm.result.length > 0)
          "
        >
          <!-- <atoms-text class="pa-2"
                ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
                ditemukan</atoms-text
              > -->
          <!-- {{ checkboxItems }} -->

          <div
            v-for="({ worker, ...rest }, i) in enableCaptainConfirm
              ? (captainConfirm && captainConfirm.result) || []
              : (requestData && requestData.result) || []"
            :key="i"
            :class="['accent', 'rounded-lg mb-2 overflow-hidden']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <div class="pa-4 mb-2">
              <v-row align="center">
                <v-col class="shrink">
                  <v-checkbox :value="checkAll ? true : undefined" @click="addItems({ ...rest, worker })" />
                </v-col>
                <v-col class="shrink">
                  <v-avatar color="grey lighten-4 rounded-lg ml-4">
                    <atoms-image :enableZoom="true" v-if="worker && worker.photo" :src="worker.photo" />
                    <v-icon light v-else>mdi-account</v-icon></v-avatar
                  >
                </v-col>
                <v-col cols="8" lg="4">
                  <atoms-text :auto="true" class="font-weight-bold">{{
                    $toCapitalize((worker && worker.fullName) || 'Nama tidak dicantumkan')
                  }}</atoms-text>
                  <atoms-text class="primary--text">{{
                    (worker && worker.email) || 'pekerja.email@website.com'
                  }}</atoms-text>
                </v-col>
                <v-col cols="12" lg="4" align="start">
                  <atoms-text class="font-weight-bold">Permintaan saldo dibuat pada : </atoms-text>
                  <atoms-text class="primary--text">
                    {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}
                  </atoms-text>
                </v-col>
                <v-col align="center" class="shrink">
                  <v-chip v-if="worker && worker.captain" small class="rounded-lg mr-2 mb-2" color="primary"
                    >Kapten</v-chip
                  >
                  <v-chip
                    v-if="rest.approved != undefined && rest.approved == true"
                    small
                    class="rounded-lg mr-2 mb-2 black--text"
                    color="secondary"
                    >Approved</v-chip
                  >
                  <v-chip
                    v-if="rest.approved != undefined && rest.approved == false"
                    small
                    class="rounded-lg mr-2 mb-2 white--text"
                    color="red"
                    >Rejected</v-chip
                  >
                </v-col>

                <v-col align="end" class="">
                  <atoms-button
                    v-if="enableCaptainConfirm"
                    icon
                    @click="
                      () => {
                        if (captainConfirmExpandedIds === i) captainConfirmExpandedIds = null
                        else captainConfirmExpandedIds = i
                      }
                    "
                  >
                    <v-icon>mdi-chevron-{{ captainConfirmExpandedIds === i ? 'up' : 'down' }}</v-icon>
                  </atoms-button>
                  <v-menu v-else offset-x class="rounded-xl">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        link
                        v-if="!enableCaptainConfirm"
                        :disabled="!worker"
                        @click="showPreApproval = { worker, ...rest }"
                        class="rounded-lg px-4"
                      >
                        <v-icon left>mdi-check</v-icon>
                        <v-list-item-title class="px-2">Approve </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        v-if="!enableCaptainConfirm"
                        :disabled="!worker"
                        @click="showPreReject = { worker, ...rest }"
                        class="rounded-lg px-4"
                      >
                        <v-icon left>mdi-close</v-icon>
                        <v-list-item-title class="px-2">Reject </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-container class="px-0 pb-0">
                <div class="flex">
                  <atoms-text style="display: inline" class="mr-2"
                    ><v-icon left small>mdi-cash-multiple</v-icon> Permintaan :
                    {{ $idCurrency(rest.nominal || rest.balance || 0) }}</atoms-text
                  >
                  <v-chip v-if="rest.approved == undefined && !enableCaptainConfirm" small class="rounded-lg"
                    >Saldo Pekerja : {{ $idCurrency(rest.balance) }}</v-chip
                  >
                </div>
                <atoms-text><v-icon left small>mdi-note</v-icon> Catatan : {{ rest.note || '-' }}</atoms-text>
                <atoms-text v-if="rest.createdBy && !enableCaptainConfirm"
                  ><v-icon left small>mdi-account-sync</v-icon> Dibuat oleh
                  <span class="primary--text"
                    >{{ rest.createdBy.nickname || '-' }} ({{ rest.createdBy.email || '' }})</span
                  ></atoms-text
                >
                <atoms-text v-if="rest.approvedBy && !enableCaptainConfirm"
                  ><v-icon left small>mdi-account-check</v-icon> Diaudit oleh
                  <span class="primary--text"
                    >{{ rest.approvedBy.nickname || '-' }} ({{ rest.approvedBy.email || '' }}) pada
                    {{ $moment(rest.approvedBy).format('dddd, DD MMMM YYYY') }}</span
                  ></atoms-text
                >

                <div v-if="!enableCaptainConfirm">
                  <atoms-text v-if="rest.clients && rest.clients.length > 0">
                    <v-icon left small>mdi-domain</v-icon> Klien : [<a
                      v-for="(x, i) in rest.clients"
                      :key="i"
                      class="text-capitalize"
                      :href="`/attendance/riwayat?search=${rest._id}&client=${x._id}`"
                    >
                      {{ x.name }}
                      {{ i + 1 !== rest.clients.length ? ', ' : '' }}</a
                    >]</atoms-text
                  >
                  <atoms-text v-else><v-icon left small>mdi-domain</v-icon> Klien : - </atoms-text>
                </div>

                <v-scroll-y-transition>
                  <div v-if="captainConfirmExpandedIds === i" class="mt-2">
                    <v-divider></v-divider>
                    <atoms-text class="mt-2">List Transaksi</atoms-text>
                    <template v-if="rest.listTransactions">
                      <div v-for="(x, i) in rest.listTransactions" :key="i" class="py-2">
                        <v-row align="center">
                          <v-col cols="12" lg="6">
                            <atoms-text class="font-weight-bold">{{ x.code || '-' }}</atoms-text>
                            <atoms-text class="font-weight-bold">{{ x.desc || '-' }}</atoms-text>
                            <atoms-text class="">
                              {{ $moment(x._createdDate).locale('en').format('DD MMMM YYYY · HH:mm:ss A') }}</atoms-text
                            >
                            <atoms-button small class="mt-2" @click="openDetailWorker(x.workerId)"
                              >Lihat pekerja</atoms-button
                            >
                          </v-col>
                          <!-- <v-col cols="12" lg="auto" align="start">
              <atoms-text class="primary--text">{{
                (x.user && x.user.nickname && `Oleh ${x.user.nickname}`) || "-"
              }}</atoms-text>
              <atoms-text
                v-if="x.fileTandaTerima && x.fileTandaTerima !== null"
                style="cursor: pointer"
                @click="openWindow(x.fileTandaTerima)"
                class="red--text text-decoration-underline"
                >Lihat tanda terima</atoms-text
              >
            </v-col> -->
                          <v-col cols="12" lg="auto" class="ml-auto shrink">
                            <atoms-text>Rp.{{ $addSeparator(x.amount, 0, 0) }}</atoms-text>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-5"></v-divider>
                      </div>
                    </template>
                    <template v-else>
                      <div>- Tidak ada transaksi</div>
                    </template>
                  </div>
                </v-scroll-y-transition>
              </v-container>
            </div>
            <div
              v-if="rest.approved != undefined"
              :class="[' white--text pa-2 px-4', rest.approved ? 'primary' : 'red']"
            >
              Permintaan di{{ rest.approved ? 'terima' : 'tolak'
              }}{{ !rest.statusNote ? '' : `, CATATAN STATUS : ${rest.statusNote}` }}
            </div>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  class="my-4"
                  v-model="page"
                  :length="enableCaptainConfirm ? captainConfirm.pagesLength : requestData.pagesLength"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada pekerja yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <v-slide-x-reverse-transition>
      <organism-workers-edit
        v-if="showDetailWorker"
        :data="showDetailWorker"
        @closed="
          () => {
            showDetailWorker = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <!-- history -->
    <v-slide-x-reverse-transition>
      <organism-workers-history-balance
        v-if="showHistory"
        :data="showHistory"
        :pureEdit="true"
        @closed="
          () => {
            showHistory = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-request-payment-complain
        v-if="showComplain"
        :id="showComplain && showComplain._id"
        :request="showComplain"
        @closed="
          () => {
            showComplain = null
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-request-payment-tutorial
        v-if="showTutorial"
        @closed="
          () => {
            showTutorial = false
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-request-payment-percentage
        v-if="showAdminSetting"
        :data="showAdminSetting"
        @close="
          () => {
            showAdminSetting = null
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      sort: '',
      checkboxItems: [],
      checkAll: false,
      showDetailWorker: null,
      enableDate: false,
      enableCaptainConfirm: false,
      captainConfirmExpandedIds: false,
      captainConfirm: null,
      requestData: null,
      statusNote: '',
      showPreApproval: false,
      showPreApprovalBulk: false,
      showPreReject: false,
      showPreRejectBulk: false,
      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      showComplain: null,
      showTutorial: false,
      showAdminSetting: null,
      showHistory: null,
      constant: ['Desc', 'Asc'],
      reportLoading: false,
      mainLoading: false,
      filter: null,
      filters: [
        {
          title: 'Semua',
          value: {
            approved: undefined,
          },
        },
        // {
        //   title: "Permintaan",
        //   value: {
        //     approved: { $exists: false },
        //   },
        // },
        {
          title: this.$status.approve,
          value: {
            approved: { $eq: true },
          },
        },
        {
          title: this.$status.reject,
          value: {
            approved: { $eq: false },
          },
        },
        // {
        //   title: this.$requestPaymentType.EWA,
        //   value: {
        //     type: this.$requestPaymentType.EWA,
        //   },
        // },
        // {
        //   title: this.$requestPaymentType.Regular,
        //   value: {
        //     type: this.$requestPaymentType.Regular,
        //   },
        // },
        {
          title: 'Rekening BCA',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.BCA || '',
              $options: 'i',
            },
          },
        },
        {
          title: 'Rekening OVO',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.OVO || '',
              $options: 'i',
            },
          },
        },
        {
          title: 'Rekening GOPAY',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.GoPay || '',
              $options: 'i',
            },
          },
        },
      ],
      exportData: {
        email: 'arsip.hayokerja@gmail.com',
      },
    }
  },
  watch: {
    async enableCaptainConfirm() {
      await this.fetchCaptainConfirm()
    },
    'client.value'() {
      if (this.client.value) {
        this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
        this.loadData()
      }
    },
    checkAll: {
      handler(value) {
        if (!!value) {
          this.checkboxItems = this.requestData.result
        } else {
          this.checkboxItems = []
        }
      },
      deep: true,
    },
    page: {
      handler() {
        if (this.enableCaptainConfirm) {
          return this.fetchCaptainConfirm()
        }
        this.getRequest()
      },
      deep: true,
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.loadData()
  },
  computed: {
    filterQuery: function () {
      const term = { $regex: this.term || '', $options: 'i' }
      return {
        pipeline: [
          {
            $sort: {
              _createdDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },

          {
            $lookup: {
              from: 'Users',
              localField: 'createdBy',
              foreignField: '_id',
              as: 'createdBy',
            },
          },
          {
            $unwind: {
              path: '$createdBy',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Users',
              localField: 'approvedBy',
              foreignField: '_id',
              as: 'approvedBy',
            },
          },
          {
            $unwind: {
              path: '$approvedBy',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Workers',
              localField: 'workerId',
              foreignField: '_id',
              as: 'worker',
            },
          },
          {
            $unwind: {
              path: '$worker',
              preserveNullAndEmptyArrays: true,
            },
          },

          {
            $match: {
              $or: [
                {
                  'worker.idNumber': term,
                },
                {
                  'worker.fullName': term,
                },
                {
                  'worker.email': term,
                },
                {
                  'worker.phone': term,
                },
              ],

              ...(this.filter && this.filter.length > 0
                ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                : []),
              _createdDate: this.enableDate
                ? {
                    $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
                    $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
                  }
                : { $exists: true },
            },
          },
          {
            $addFields: {
              clientIds: {
                $reduce: {
                  input: '$worker.clientIds',
                  initialValue: [],
                  in: {
                    $cond: {
                      if: {
                        $eq: [
                          {
                            $type: '$$this',
                          },
                          'string',
                        ],
                      },
                      then: { $concatArrays: ['$$value', ['$$this']] },
                      else: { $concatArrays: ['$$value', '$$this'] },
                    },
                  },
                },
              },
            },
          },
          {
            $match: {
              clientIds: this.client.item?._id ? { $in: [this.client.item._id] } : undefined,
            },
          },
          {
            $lookup: {
              from: 'Clients',
              localField: 'clientIds',
              foreignField: '_id',
              as: 'clients',
            },
          },
          {
            $project: {
              'createdBy.password': 0,
              'createdBy.notifTokens': 0,
              'createdBy.roles': 0,
              'approvedBy.password': 0,
              'approvedBy.notifTokens': 0,
              'approvedBy.roles': 0,
            },
          },
        ],
      }
    },
  },
  methods: {
    async openDetailWorker(_id) {
      this.$store.commit('SET', { loading: true })

      const resp = await this.$store.dispatch('worker/getAll', {
        query: {
          _id,
        },
        returnOnly: true,
        page: 1,
        limit: 1,
      })
      if (resp?.[0]?._id) this.showDetailWorker = resp?.[0]
      this.$store.commit('SET', { loading: false })
    },
    async fetchCaptainConfirm() {
      if (this.enableCaptainConfirm) {
        this.mainLoading = true
        this.$store.commit('SET', { loading: true })
        const resp = await this.$api.get('WorkerBalances/getConfirmCaptain', {
          params: {
            jsonQuery: JSON.stringify({
              pipeline: [
                {
                  $lookup: {
                    from: 'Workers',
                    localField: 'workerId',
                    foreignField: '_id',
                    as: 'worker',
                  },
                },
                {
                  $unwind: {
                    path: '$worker',
                    preserveNullAndEmptyArrays: true,
                  },
                },
                {
                  $sort: {
                    _createdDate: -1,
                  },
                },
                // {
                //   $lookup: {
                //     from: "Workers",
                //     let: { workersId: "$listTransactions.workerId" },
                //     pipeline: [
                //       { $match: { $expr: { $in: ["$_id", "$$workersId"] } } },
                //     ],
                //     as: "$listTransactions.workerss",
                //   },
                // },
              ],
            }),
            page: this.page,
            limit: this.limit,
          },
        })
        console.log(resp)
        if (resp?.result?.length > 0) this.captainConfirm = resp
        this.mainLoading = false

        this.$store.commit('SET', { loading: false })
      }
    },
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 1,
          limit: 0,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async loadData() {
      this.page = 1
      this.statusNote = ''
      await this.getRequest()
    },
    async addItems(item) {
      if (!this.checkboxItems?.find((x) => x._id == item._id)) {
        this.checkboxItems.push(item)
      } else {
        this.checkboxItems = this.checkboxItems?.filter((x) => x._id != item._id)
      }
      if (this.checkboxItems?.length >= this.requestData?.result?.length) {
        this.checkAll = true
      } else if (this.checkAll) {
        const temp = [...this.checkboxItems]
        this.checkAll = false
        this.checkboxItems = temp
      }
    },
    async approveBulkRequest(payload, approved = true) {
      this.mainLoading = true
      try {
        if (Array.isArray(payload)) {
          payload = payload?.filter((it) => !it?.approved)
        }
        if (Array.isArray(payload) && payload?.length > 0) {
          await this.$store.dispatch('requestPayments/bulkApproveEditWorkerBalances', {
            _ids: payload?.map((it) => it?._id),
            approved,
            statusNote: this.statusNote,
          })
        } else {
          alert('Tidak ada permintaan dari pekerja yang Anda list')
        }
        this.checkAll = false
        this.checkboxItems = []
      } catch (error) {
        throw new Error(`Salah Satu Permintaan Gagal : ${error.message || error}`)
      } finally {
        await this.loadData()
        this.mainLoading = false
      }
    },
    async approveRequest(payload, approved = true) {
      this.mainLoading = true
      // let errors = []
      try {
        // if (Array.isArray(payload)) {
        //   payload = payload?.filter((it) => !it?.approved)
        // }
        if (
          payload?._id &&
          !payload?.approved &&
          confirm(
            `Apakah Anda yakin ingin menerima permintaan perubahan saldo dari ${
              payload?.createdBy?.fullName || payload?.createdBy?.email || '-'
            } untuk ${payload?.worker?.fullName || payload?.worker?.email || '-'}`,
          )
        ) {
          delete payload?.worker
          // await Promise.all(
          //   payload?.map(async (it) => {
          //     delete it?.balance
          //     return
          // console.log({ approved, ...payload, statusNote: this.statusNote })
          await this.$store.dispatch('requestPayments/updateEditWorkerBalances', {
            ...payload,
            approved,
            statusNote: this.statusNote,
          })
          //   })
          // ).then((result) => {
          //   console.log(result)
          // })
          this.checkAll = false
          this.checkboxItems = []
          await this.loadData()
        } else if (payload?.approved) {
          // alert(
          //   `Permintaan tersebut sudah disetujui sebelumnya ${
          //     payload?.approvedBy
          //       ? `oleh ${
          //           payload?.approvedBy?.name || payload?.approvedBy?.email
          //         }`
          //       : "."
          //   }`
          // )
          alert(`Permintaan tersebut sudah disetujui sebelumnya`)
        }
        // else {
        //   this.$showDialog({
        //     title: `Permintaan Gagal`,
        //     body: "",
        //   })
        // }
        // if (errors?.length > 0) {
        //   this.$showDialog({
        //     title: `Beberapa pekerja gagal menerima permintaan karena ${
        //       errors?.[0]?.error || "-"
        //     }`,
        //     body: `Daftar pekerja yang dimaksud ${errors
        //       ?.map((it) => it?.fullName || it?.email)
        //       ?.join()}`,
        //   })
        // }
      } catch (error) {
        throw new Error(error)
      } finally {
        this.mainLoading = false
      }
    },
    async getRequest() {
      this.mainLoading = true
      try {
        this.requestData = await this.$store.dispatch('requestPayments/getEditWorkerBalances', {
          custom: this.filterQuery,
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
